import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CostExplorer } from 'aws-sdk';
import { PatientService } from 'src/app/service/patient.service';

@Component({
	selector: 'app-supervisorleads-scripts',
	templateUrl: './supervisorleads-scripts.component.html',
	styleUrls: ['./supervisorleads-scripts.component.css']
})
export class SupervisorleadsScriptsComponent implements OnInit {
	scriptId: string;
	accessToken: any;
	apidata: any;
	safeHtml: SafeHtml;
	loadspinner: any;
	constructor(private route: ActivatedRoute, private patientService: PatientService, private sanitizer: DomSanitizer) {
		this.route.queryParams.subscribe(params => {
			// Base64 decode the parameter key
			const encodedKeyScriptId = btoa('script_id'); // Base64 encode the key 'script_id'

			// Extract the encoded value from the URL
			const encodedScriptId = params[encodedKeyScriptId];

			if (encodedScriptId) {
				// Base64 decode the value
				this.scriptId = atob(decodeURIComponent(encodedScriptId));
			}
		});
	}

	ngOnInit() {
		this.loadspinner = true;
		this.accessToken = localStorage.getItem('access_token');
		this.patientService.getApi_getscriptdetails(this.accessToken, this.scriptId).subscribe((data) => {
			// this.DispositionList = data['disposition_list'];
			this.apidata = data;
			const decodedHtml = this.decodeHtml(this.apidata.description);
			const updatedHtml = this.addTargetBlankToLinks(decodedHtml);
			this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(updatedHtml);
			this.loadspinner = false;
		},
			(err: any) => {
			}
		);
	}

	// Helper function to decode HTML entities
	decodeHtml(html: string): string {
		const txt = document.createElement('textarea');
		txt.innerHTML = html;
		return txt.value;
	}

	// Function to add target="_blank" to all anchor (<a>) tags
	addTargetBlankToLinks(html: string): string {
		// Create a DOM parser to manipulate the HTML
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');

		// Get all anchor tags and add target="_blank"
		const links = doc.querySelectorAll('a');
		links.forEach(link => {
			link.setAttribute('target', '_blank');
		});

		// Return the updated HTML string
		return doc.body.innerHTML;
	}

}
