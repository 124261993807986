import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { Patient, CaseDetails, UserList } from '../models/Patient';
import {  Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class PatientService {
	headers:any;
	authToken:any;
	caseDetails:any;
	patientDetails:any;
	userList:any;
	campaignList:any;
	constructor(private http: HttpClient) { }


	loginAuth(grantType: string, client_id: string, client_secret:string, username:string, password: string) {
		grantType = 'client_credentials';
		client_id = 'cea98ac6-4698-3a89-ec90-66ebff42e231';
		client_secret = 'CzJwyznmgYJftSXx3n';
		
		this.headers = new HttpHeaders()
			.set('content-type', 'application/vnd.api+json')
			.set('Access-Control-Allow-Origin', '*')
			.set('Content-Security-Policy', 'self')
			.set('X-Frame-Options', 'SAMEORIGIN')
			.set('X-Content-Type-Options', 'nosniff')
			.set('Referrer-Policy', 'no-referrer, strict-origin-when-cross-origin')
			.set('Permissions-Policy', 'self')
			.set('Accept', "application/vnd.api+json");

		const body = {"grant_type":grantType,"client_id":client_id,"client_secret":client_secret}
		return this.authToken = this.http.post(environment.SERVER_URL_PREFIX + 'access_token', body, this.headers);
	}

	
	getToken(grantType: string, client_id: string, client_secret:string) {
			grantType = grantType;
			client_id = client_id;
			client_secret = client_secret;
			
			this.headers = new HttpHeaders()
				.set('content-type', 'application/vnd.api+json')
				.set('Access-Control-Allow-Origin', '*')
				.set('Content-Security-Policy', 'self')
				.set('X-Frame-Options', 'SAMEORIGIN')
				.set('X-Content-Type-Options', 'nosniff')
				.set('Referrer-Policy', 'no-referrer, strict-origin-when-cross-origin')
				.set('Permissions-Policy', 'self')
				.set('Accept', "application/vnd.api+json");
	
			const body = {"grant_type":grantType,"client_id":client_id,"client_secret":client_secret}
			return this.authToken = this.http.post(environment.SERVER_URL_PREFIX + 'access_token', body, this.headers);
	}

	getUserList(accessToken:string, start_count:number, records_per_page:number, status:String, dispositions:String, username:String, campaign:String, pref_comm_channel:String, CustomerName:string, sortby:string, sortorder:string):Observable<UserList[]>{
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/json')
			.set('Accept', "application/json");

		this.userList = this.http.get<UserList[]>(environment.REST_URL + 'getLeadsRecords?start_count='+start_count+ '&records_per_page='+records_per_page+'&status='+status+'&dispositions='+dispositions+'&username='+username+'&utm_campaign='+campaign+'&pref_channel='+pref_comm_channel +'&customer_name='+CustomerName +'&sort_by='+sortby +'&sort_order='+sortorder, {'headers': this.headers});
		return this.userList;
	}

	getCampaignsList(accessToken:string, start_count:number, records_per_page:number, status:String, dispositions:String, username:String){
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		this.campaignList = this.http.get(environment.REST_URL +"getCampaignList", {'headers': this.headers});
		return this.campaignList;
	}

	getApi_DispositionList(accessToken:string){
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		let DispositionList = this.http.get(environment.REST_URL +"getdisposition-prefcomm-script-list", {'headers': this.headers});
		return DispositionList
	}

	getApi_getscriptdetails(accessToken:string, scriptId:string){
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		let scriptdetails = this.http.get(environment.REST_URL +'get-script-details?script_id='+scriptId, {'headers': this.headers});
		return scriptdetails
	}

	get_callStatusAPI(accessToken:string, session_id:any, campaign_id:any){
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");
			// const body = {"event_id":event_ID}
			const body = {
							  "session_id":session_id,
    						  "campaign_id":campaign_id
						 }
		let records = this.http.post("https://kgyh472q87.execute-api.us-east-1.amazonaws.com/dev/get_record", body, {'headers': this.headers});
		return records;
	}

	getDetails(caseNumber:string,accessToken:string):Observable<CaseDetails[]>{
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		const body = {"caseNumber":caseNumber}
		this.caseDetails = this.http.post<CaseDetails[]>(environment.REST_URL + 'get-case-record', body, {'headers': this.headers});
		return this.caseDetails;
	};

	createOrder(accessToken:string,orderName:string,contactName:string,permitType:string,orderPriority:string,orderStatus:string,description:string,assignedPerson:string){
		this.headers = new HttpHeaders()
			.set('Authorization', "Bearer "+accessToken)
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		const body = {
			"name":orderName,
			"contactName":contactName,
			"permitType":permitType,
			"priority":orderPriority,
			"status":orderStatus,
			"description":description,
			"assignedPerson":assignedPerson
		}
		this.patientDetails = this.http.post(environment.REST_URL + 'create-patient-order', body, {'headers': this.headers});
		return this.patientDetails;
	};

	logoutLeads(){
		this.headers = new HttpHeaders()
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

		let res = this.http.delete(environment.SERVER_URL_PREFIX+'V8/logout');
		return res;
	}

	makecallApi(number, userName, arndata,customer_name){
		
		this.headers = new HttpHeaders()
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

			const body = {
				"dataset":[
					[userName,number,customer_name]
				],
				  "agentarn" :arndata
			}

		let res = this.http.post("https://zs17pmaofb.execute-api.us-east-1.amazonaws.com/dev/outboundcall/", body, {'headers': this.headers});
		return res;
	}

	makeoutboundcallApi(body){
		this.headers = new HttpHeaders()
			.set('content-type', 'application/vnd.api+json')
			.set('Accept', "application/vnd.api+json");

			
		let res = this.http.post("https://v26ho8ef81.execute-api.us-east-1.amazonaws.com/dev/initiate_calls", body, {'headers': this.headers});
		return res;
	}

	updateDisposition(accessToken, body){
		this.headers = new HttpHeaders()
		.set('Authorization', "Bearer "+accessToken)
		.set('content-type', 'application/vnd.api+json')
		.set('Accept', "application/vnd.api+json");

			
		let res = this.http.post("https://crm.connectandengagehc.com/public/Api/V8/custom/updateLeads", body, {'headers': this.headers});
		return res;
	}


	sendmeaage_Api(body){
		this.headers = new HttpHeaders()
			// .set('content-type', 'application/vnd.api+json')
			// .set('Accept', "application/vnd.api+json");/
			.set('content-type',  "*/*")
			.set('Accept', "*/*");

			
		let res = this.http.post(environment.smssend_url, body, {'headers': this.headers});
		return res;
	}

}
